@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.mobile {
  position: fixed!important;
  padding: 20px!important;
  padding: 20px!important;
  background-color: rgb(35, 35, 35)!important;
  z-index: 999!important;
  width: 100%!important;
  box-sizing: border-box;
  height: 100vh!important;
  justify-content: start!important;
}

@media screen and (max-width: 650px) {
  .c {
    position: fixed;
    width: 100%;
    height: 100%;
    background: linear-gradient(#353535, #232323);
    z-index: 10000000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    // animation: slide .6s ease;
    // animation-iteration-count: 1;
  }
  .home__content__info {
    .c {
      padding: 0;
      width: 100%;
    }
  }

  .dd {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 45px;
  }

  li {
    margin-bottom: 20px;
    // margin-left: 8px;
  }

  .dd__link {
    color: white;
    font-size: 24px;
    padding: 0;
    width: 100%;
    display: flex;
  }

  .dd__link_label {
    position: relative;
    width: 90%;
    cursor: pointer;
    line-height: 33px;
    height: 33px;
  }

  .dd__link_arrow {
    text-align: center;
    color: white;
    // padding-top: 7px;
    height: 33px;
    line-height: 33px;
    width: 10%;
    font-size: 30px;
    cursor: pointer;
    opacity: 0%;
    transition: 0.2s;
  }

  .dd__link_arrow:hover:focus {
    opacity: 100%;
  }

  .dd__link_label:hover + .dd__link_arrow {
    opacity: 100%;
  }

  .header {
    margin-top: 10px;
    display: inline-block;
    flex-direction: row;
    width: 90%;
  }

  .header__text {
    color: white;
    font-size: 50px;
    font-family: "itrFont";
  }

  .header__x {
    color: white;
    font-size: 25px;
    font-family: "itrFont";
    border: none;
    float: right;
    margin-top: 13px;
    margin-right: 5px;
    cursor: pointer;
  }
}

.slideIn{
  animation: slideIn .6s ease;
}

.slideOut{
  animation: slideOut .6s ease;
}

@keyframes slideIn {
  0% {
    margin-top: -100vh;
    opacity: 0%;
  }
  100% {
    margin-top: 0vh;
    opacity: 100%;
  }
}

@keyframes slideOut {
  0% {
    margin-top: 0vh;
    opacity: 100%;
  }
  100% {
    margin-top: -100vh;
    opacity: 0%;
  }
}