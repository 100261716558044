@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.awards {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgb(54, 54, 54);
  display: flex;
  justify-content: center;
  align-items: center;
}

.awards__content {
    background-color: red;
    width: 80%;
    height: auto;
    padding: 10px;
}

.awards__content__title {
    font-weight: bold;
    font-size: 300%;
    color: rgb(255, 255, 255);
    font-family: "itrFont";
    margin-bottom: 1%;
}

.awards__content__list_item {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
    height: 100px;
}