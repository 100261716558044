@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.home {
  width: 100%;
  opacity: 100%;
  -webkit-animation: fade 0.9s ease-in;
  -moz-animation: fade 0.9s ease-in;
  -ms-animation: fade 0.9s ease-in;
  -o-animation: fade 0.9s ease-in;
  animation: fade 0.9s ease-in;
}

.home__content__panel {
  background-color: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.home__content__panel__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home__content__panel__title {
  position: absolute;
  color: white;
  text-align: center;
  font-size: 70px;
  font-family: "itrFont";
  animation: textSlide 1s ease;
  animation-iteration-count: 1;
  opacity: 100%;
  width: 100%;
  letter-spacing: 2px;
  filter: drop-shadow(0 0 0.75rem rgb(77, 77, 77));
  z-index: 1;
}

.home__content__info {
  height: auto;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background: linear-gradient(#353535, #232323);
  display: flex;
  align-items: center;
  justify-content: center;

}

.c {
  margin: 0px;
  padding: 0px;
  width: 90%;
  padding: 50px;
  height: auto;
  position: relative;
  display: flex;
  opacity: 100%;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  z-index: 0;
}

.dropdown {
  position: fixed;
  z-index: 999;
  background-color: #232323;
}

@media screen and (max-width: 650px) {
  // Phones
  .home__content__panel__title {
    font-size: 45px;
  }
}

// Animations

@keyframes textSlide {
  0% {
    margin-top: 50vw;
  }
  0% {
    opacity: 0%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
}
