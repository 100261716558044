@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.about {
  background-color: #232323;
  width: 100%;
  min-height: 100vh;
  height: auto;
  opacity: 100%;
  overflow-x: hidden;
  display: flex;
  -webkit-animation: fade 0.9s ease-in;
  -moz-animation: fade 0.9s ease-in;
  -ms-animation: fade 0.9s ease-in;
  -o-animation: fade 0.9s ease-in;
  animation: fade 0.9s ease-in;
  background: linear-gradient(#3b3b3b, #1a1a1a);
}

.about__content {
  position: relative;
  margin-top: 70px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.about__content__main {
  width: 100vw;
  height: auto;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__content__main__container {
  padding: 10px 10px 20px 10px;
  border-radius: 10px;
  background-color: rgb(66, 66, 66);
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  cursor: default;
}

.about__content__main__container h1,
.about__content__blocks_block h1 {
  color: white;
  opacity: 100%;
  font-size: 40px;
  font-weight: bold;
  font-family: "itrFont";
  padding: 10px;
}

.about__content__main__container span,
.about__content__blocks_block span {
  color: rgb(238, 238, 238);
  opacity: 100%;
  font-size: 20px;
  font-family: "itrFontMedium";
  padding: 10px;
  line-height: 32px;
  border-radius: 10px;
}

.about__content__blocks_block span {
  margin-bottom: 30px;
}

.about__content__blocks {
  margin-top: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-right: calc(-1 * (100vw - 100%));
}

.about__content__blocks_block {
  position: relative;
  border-radius: 10px;
  background-color: rgb(66, 66, 66);
  width: 25vw;
  margin-left: 3vw;
  margin-right: 3vw;
  min-height: 38vh;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  cursor: pointer;
}

.about__content__blocks_block p {
  position: absolute;
  bottom: 0;
  color: rgb(136, 136, 136);
  opacity: 100%;
  font-size: 20px;
  font-family: "itrFontMedium";
  padding: 10px;
  line-height: 32px;
  border-radius: 10px;
}

.about__content__blocks_block:hover,
.about__content__main__container:hover {
  background-color: #666666;
}

@media screen and (max-width: 1550px) {
  .about__content__blocks {
    flex-direction: column;
  }

  .about__content__blocks_block {
    margin-bottom: 6vh;
    width: 80vw;
  }

  .about__content__blocks_block h1 {
    font-size: 35px;
  }
}

@media screen and (max-width: 650px) {
  .about__content__main__container {
    width: 90vw;
  }

  .about__content__blocks_block {
    width: 90vw;
  }
  
  .about__content__main__container span,
  .about__content__blocks_block span {
    font-size: 18px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
}
