@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.footer {
  background-color: #232323;
  width: 100%;
  height: 100px;
  border-top: 1px solid rgb(46, 46, 46);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.footer__links {
  width: 80vw;
  height: 50px;
  padding: 0px;
  margin: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__links_ul {
  margin: 0px;
  padding: 0px;
  width: 80vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__links_ul_li {
  padding: 0px;
  margin: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__links_ul_li_a {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__links_ul_li_svg {
  width: 40px;
  height: 40px;
  filter: invert(100%) sepia(10%) saturate(1000%) hue-rotate(10deg)
    brightness(100%) contrast(100%);
  opacity: 30%;
  transition: 0.3s;
  padding: 0px;
  margin: 0px;
}

.footer__links_ul_li_svg:hover {
  opacity: 70%;
}

.footer__toes {
  display: flex;
  width: 80vw;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.footer__toes__links {
    display: flex;
}

.footer__toes__links_link {
  text-align: center;
  width: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 30px;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 650px) {
  // Phones
  .footer__links_ul_li_svg {
    width: 30px;
    height: 30px;
  }

  .footer__links_ul_li {
    margin: 15px;
  }

  .footer__toes__links_link_a {
    font-size: 17px;
  }

  .footer__toes__links_link {
    width: 80px;
  }
}