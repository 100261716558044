@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.gallery {
  background-color: #232323;
  width: 100%;
  min-height: 100vh;
  height: auto;
  opacity: 100%;
  overflow-x: hidden;
  display: flex;
  -webkit-animation: fade 0.9s ease-in;
  -moz-animation: fade 0.9s ease-in;
  -ms-animation: fade 0.9s ease-in;
  -o-animation: fade 0.9s ease-in;
  animation: fade 0.9s ease-in;
  background: linear-gradient(#3b3b3b, #1a1a1a);
}

.gallery_wrap {
  flex-wrap: wrap;
  padding: 24px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.gallery__title {
  margin: 20px 0 20px 20px;
  font-family: itrFontMedium;
  color: #fff;
  text-align: center;
}
