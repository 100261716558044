@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.bubble {
  color: rgb(255, 255, 255);
  font-family: "itrFontMedium";
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 30px; 
}

.bubble_main {
  width: 100%;
}

.bubble_main h1 {
  font-weight: bold;
  font-size: 300%;
  border-left: 5px solid rgb(255, 142, 142);
  padding-left: 15px;
  border-top-left-radius: 10px;
  margin-bottom: -5px;
}

.bubble_main h2 {
  font-weight: bold;
  font-size: 400%;
  border-left: 5px solid rgb(255, 142, 142);
  padding-left: 15px;
  border-bottom-left-radius: 10px;
  margin-bottom: 25px;
}

.bubble_main div {
  height: auto;
  display: flex;
  overflow-wrap: break-word;
  font-weight: normal;
  font-size: 150%;
  color: #d6d6d6;
  background-color: rgb(59, 59, 59);
  border-radius: 10px;
  line-height: 35px;
  padding: 15px;
  flex-direction: column;
}

.bubble_main a {
  color: rgb(141, 238, 255);
}

@media screen and (max-width: 650px) {
  // Phones
  .bubble_main {
    width: auto;
  }

  .bubble_main h1 {
    font-size: 150%;
  }

  .bubble_main h2 {
    font-size: 250%;
  }

  .bubble_main div {
    font-size: 100%;
    line-height: 25px;
  }
}
