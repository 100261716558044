@font-face {
  font-family: itrFont;
  src: url(../font/LeagueSpartan-ExtraBold.ttf);
}

@font-face {
  font-family: itrFontMedium;
  src: url(../font/LeagueSpartan-Medium.ttf);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  background-color: transparent;
  position: fixed;
  width: 100%;
  overflow-y: hidden;
  transition: 0.5s;
  z-index: 1000;
}

.navbar__logotext {
  color: white;
  font-size: 50px;
  font-family: "itrFont";
  margin-top: 6px;
  margin-left: 50px;
  transition: 0.5s;
  background: linear-gradient(
    to right,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  -webkit-background-clip: text;
  background-clip: text;
  animation: navbar__logotext 6s ease-in-out infinite;
  background-size: 400% 100%;
  cursor: pointer;
}

#logo {
  text-decoration: none;
}

.navbar__logotext:hover {
  color: transparent;
}

@keyframes navbar__logotext {
  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

li {
  display: inline;
}

li a {
  color: rgb(216, 216, 216);
  text-decoration: none;
  font-family: "itrFontMedium";
  font-size: 20px;
  margin-left: 3px;
  margin-right: 3px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: 0.3s;
}

li a:hover {
  color: white;
}

.nav__placeholder {
  padding: 10px;
  width: 70px;
  height: 20px;
  border: none;
  outline: none;
  background: transparent;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin-right: 50px;
}

.nav__login {
  padding: 10px;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  font-family: "itrFontMedium";
  width: 70px;
  height: 20px;
  border: none;
  outline: none;
  color: #ffffff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  margin-right: 50px;
}
.nav__login::before {
  content: "";
  background: linear-gradient(
    to right,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glow 30s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}
@keyframes glow {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.nav__login:active {
  color: #000;
}
.nav__login:active:after {
  background: transparent;
}
.nav__login:hover:before {
  opacity: 1;
}

.navbar__hamburger {
  display: none;
}

.hamburger-react {
  display: none;
}

@media screen and (max-width: 912px) {
  // Tablets
}

@media screen and (max-width: 650px) {
  // Phones
  .navbar__logotext {
    margin-top: 6px;
    margin-left: 20px;
  }

  ul {
    display: none;
  }

  .hamburger-react {
    color: #fff;
    display: block;
    margin-right: 10px;
  }
  .navbar__hamburger {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    cursor: pointer;
  }

  .navbar__hamburger_line {
    background-color: rgb(255, 255, 255);
    width: 30px;
    height: 3px;
    margin: 2px;
    border: none;
    border-radius: 10px;
  }
}
