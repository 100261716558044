.c {
  opacity: 100%;
  -webkit-animation: fade 0.9s ease-in;
  -moz-animation: fade 0.9s ease-in;
  -ms-animation: fade 0.9s ease-in;
  -o-animation: fade 0.9s ease-in;
  animation: fade 0.9s ease-in;
  background: linear-gradient(#3b3b3b, #1a1a1a);
  position: relative;
  margin: 0px;
  padding: 0px;
  width: 100vw;
}

.error {
  background-color: #3d3d3d;
  width: 100%;
  overflow: hidden;
  display: flex;
  height: calc(100vh - 101px);
  position: relative;
  top: 0;
  left: 0;
}

.error__photo {  margin: 0px;
  width: 100%;
  min-width: 900px;
}

.e {
  margin: 0px;
  position: absolute;
  width: 100vw;
  max-width: 500px;
  aspect-ratio: 16/9;
  border-radius: 30px;
  left: 0; 
  right: 0; 
  top: 0;
  bottom: 0;
  margin: auto
}

.e__background {
  margin: 0px;
  position: relative;
  width: 100vw;
  max-width: 500px;
  aspect-ratio: 16/9;
}

.e__container {
  margin: 0px;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 20px;
  top: 0;
  width: 100vw;
  max-width: 500px;
  aspect-ratio: 16/9;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.e__container__titleCon {
  width: 100%;
  height: 15%;
}

.e__container__messageCon {
  width: 100%;
  height: 77%; 
}

.e__container__titleCon__title {
  font-family: "Tahoma";
  margin-top: 1%;
  margin-left: 12%;
  font-size: 1.5rem;
  color: white;
}

.e__container__messageCon__message {
  font-family: "Tahoma";
  margin-top: 1%;
  margin-left: 12%;
  font-size: 1.3rem;
  color: black;
  margin-left: 24%;
  margin-top: 10%;
  margin-right: 20px;
  line-height: 35px;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
}