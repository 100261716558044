* {
  padding: 0px;
  margin: 0px;
  background-color: transparent;
}

.container {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #232323;
}

html {
  overflow-y: scroll;
}
